import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Avatar, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";

import iconCloud from "../../img/Cloud.svg";

const ComponentDrag = ({ fnReturn = null }) => {
  const [t] = useTranslation("global");
  const [dragging, setDragging] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let file = acceptedFiles[0];
      setDragging(true);

      fnReturn(file);
    },
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <div
      data-testid="input-area"
      style={{
        border: dragging ? "2px dashed gray" : "2px dashed lightgray",
        height: "95%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "16px",
        padding: "40px 16px 80px 16px",
        gap: "24px",
        margin: "auto",
        position: "relative",
        backgroundColor: dragging ? "#C2D1D975" : "#FFFFFF",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div
        style={{
          color: "gray",
          fontSize: "24px",
        }}
      >
        <Avatar
          src={"../" + iconCloud}
          sx={{
            width: 71,
            height: 71,
            marginRight: "12px",
            margin: "10% auto",
          }}
        />
      </div>
      <Typography
        fontSize={"24px"}
        sx={{
          textAlign: "center",
          fontFamily: "Roboto",
          letterSpacing: "0.243px",
          fontWeight: 600,
          lineHeight: "33px",
          color: "#343C46",
        }}
      >
        {t("Risk.BankPayments.DragYourFilesHere")}
      </Typography>
      <Typography
        fontSize={"12px"}
        sx={{
          letterSpacing: "0.24px",
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: "18px",
          textAlign: "center",
          color: "#4C5866",
        }}
      >
        {t("Risk.BankPayments.FindComputerByClickingUploadFile")}
      </Typography>
      <Button
        variant="contained"
        startIcon={<ArrowUpwardIcon />}
        sx={{
          textTransform: "none",
          borderRadius: "2px",
          borderColor: "#FFFFFF",
          fontSize: "12px",
          fontWeight: "bold",
          height: "50px",
          width: "150px",
          gap: "8px",
          color: "#404A56",
          backgroundColor: "#FFC10E",
          "&:hover": {
            backgroundColor: "#FFC10E",
          },
        }}
      >
        {t("Risk.BankPayments.UploadFile")}
      </Button>
    </div>
  );
};
ComponentDrag.propTypes = {
  fnReturn: PropTypes.func,
};
export default ComponentDrag;
