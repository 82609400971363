export function formatDateString(date) {
  return new Date(date).toLocaleDateString("en-GB", { timeZone: 'UTC' });
}

export function formatDateTimeString(date) {
  const formatedDate = new Date(date);

  if (isNaN(formatedDate.getTime())) {
    return "";
  }
  
  const dateString = formatedDate.toLocaleString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const [datePart, timePart] = dateString.split(", ");
  const formattedDate = `${datePart} - ${timePart?.toLowerCase()}`;
  return formattedDate;
}
