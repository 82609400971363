import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import useLoanDetails from "../../../hooks/backoffice/useLoanDetails";
import LoanEnum from "../../../utils/enums/loan.enum";
import { formatCurrency } from "../../../utils/formatCurrency";
import { formatDateTimeString } from "../../../utils/formatDate";
import { shouldShowMoratoriumData } from "../../../utils/moratoriumData";
import CustomButton from "./customButton";


const CustomSection = ({ title, data = [], isLoading }) => {
  return (
    <section style={{ marginTop: "32px" }}>
      <h3 style={{ fontSize: "16px", marginBottom: "16px" }}>{title}</h3>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
        {data.map((e, index) => (
          <div key={index} style={{ marginBottom: "16px", width: "70%" }}>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {e.label}
            </label>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#343C46",
                margin: "0",
                wordWrap: "break-word",
              }}
            >
              {isLoading ? <Skeleton width="50%" /> : e.value}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

const verifyValue = (value) => {
  return value || '--';
};

export default function LoanInfoModal({ open, onClose, loanId }) {
  const [t] = useTranslation("global");
  const { loan, isLoading } = useLoanDetails({ loanId });
  const loanEnum = new LoanEnum(t);

  const handleClose = () => {
    onClose();
  };
  const moratoriumDataSection = shouldShowMoratoriumData(loan);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            borderRadius: "16px",
            width: "100%",
            minWidth: "50%",
            padding: "36px",
          },
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ fontWeight: "700", fontSize: "28px" }}>{t("Backoffice.FinancialProduct.LoanDetails.Title")}</h2>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.CustomerID"),
              value: verifyValue(loan?.customerId),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.ContractNumber"),
              value: verifyValue(loan?.contractNumber),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanID"),
              value: verifyValue(loan?.loanId),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanType"),
              value: verifyValue(loanEnum.loanType[loan?.loanType]),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.Currency"),
              value: verifyValue(loan?.currency),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.FinancialActivity"),
              value: verifyValue(loan?.financialActivity),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.LoanInfo.LoanTerm"),
              value: verifyValue(loan?.loanTerm),
            },
          ]}
        />
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.DisbursementCity"),
              value: verifyValue(loan?.disbursementCity),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.RequestTime"),
              value: verifyValue(formatDateTimeString(loan?.requestTime)),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.DisbursementTime"),
              value: verifyValue(formatDateTimeString(loan?.disbursementTime)),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.LoanDestination"),
              value: verifyValue(loan?.loanDestination),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.AmountRequested"),
              value: formatCurrency(loan?.amountRequested),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.DisbursementInfo.AmountDisbursed"),
              value: verifyValue(loan?.amountDisbursed),
            },
          ]}
        />
        <CustomSection
          title={t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.Title")}
          isLoading={isLoading}
          data={[
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.EarlyCancellationDays"),
              value: verifyValue(loan?.earlyCancellationDays),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.PaymentsQuantity"),
              value: verifyValue(loan?.paymentsQuantity),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.TransactionExpirationDate"),
              value: verifyValue(formatDateTimeString(loan?.transactionExpirationDate)),
            },
            {
              label: t("Backoffice.FinancialProduct.LoanDetails.PaymentInfo.TransactionCancellationDate"),
              value: verifyValue(formatDateTimeString(loan?.transactionCancellationDate)),
            },
          ]}
        />
        {moratoriumDataSection ? (
          <CustomSection
            title="Moratorium Data"
            isLoading={isLoading}
            data={[
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.MoratoriumDays"),
                value: verifyValue(loan?.moratoriumDays),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.OperationCurrentBalance"),
                value: verifyValue(loan?.operationCurrentBalance),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.MoratoriumInterest"),
                value: verifyValue(loan?.moratoriumInterest),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.AgentCode"),
                value: verifyValue(loan?.agentCode),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.CompensatoryInterest"),
                value: verifyValue(loan?.compensatoryInterest),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.PlaceAndMethodPayment"),
                value: verifyValue(loan?.placeAndMethodPayment),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.PunitiveInterest"),
                value: verifyValue(loan?.punitiveInterest),
              },
              {
                label: t("Backoffice.FinancialProduct.LoanDetails.MoratoriumData.AdministrativeExpenses"),
                value: verifyValue(loan?.administrativeExpenses),
              },
            ]}
          />
        ) : null}
      </DialogContent>
      <DialogActions sx={{ marginTop: "16px" }}>
        <CustomButton width="20%" onClick={handleClose}>
          Close
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
