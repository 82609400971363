import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { Box, CardContent, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { Base64 } from "js-base64";

import avatarsIcon from "../img/avatars.svg";
import sendIcon from "../img/sendIcon.svg";

import { SnackBar } from "../components/utils/alert";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import FileErrorAlert from "../components/BankPayments/FileErrorAlert";
import BasicTable from "../components/commons/basicTable";
import ComponentDrag from "../components/commons/ComponentDrag";
import GenericBackdrop from "../components/commons/GenericBackdrop";
import Sesion from "../components/commons/sesion";
import ThemeRisks from "../themes/ThemeRisks";

export default function MigrateMerchants() {
  const [t] = useTranslation("global");
  const axiosPrivateRisk = useAxiosPrivate();
  const [viewTable, setViewTable] = useState(false);
  const [data, setData] = useState([]);
  const [dataErrors, setDataErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [readyMigrate, setReadyMigrate] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleLoaderOpen = () => setIsLoading(true);
  const handleLoaderClose = () => setIsLoading(false);

  const columnsCsv = [
    "client_id",
    "email",
    "type_of_company",
    "economic_activity",
    "business_name",
    "trade_name",
    "names",
    "first_name",
    "second_name",
    "role",
    "code_phone",
    "main_owner",
    "code_postal",
    "birthdate",
    "digital_stores",
  ];
  const columns = [
    t("Migration.columns.complete_name"),
    t("Migration.columns.code_phone"),
    t("Migration.columns.email"),
  ];
  const columnsError = [
    t("Migration.columns.complete_name"),
    t("Migration.columns.code_phone"),
    t("Migration.columns.email"),
    "Datos faltantes",
  ];
  const condition = ["status", "merchant_id"];

  const overlayStyle = {
    display: showError ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#343C4699",
    zIndex: 999,
  };

  const convertCSVToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j].trim()] = currentLine[j].trim();
      }
      result.push(obj);
    }

    return result;
  };

  const validateColumn = (column, value) => {
    let regex;
    let validation;
    let age;
    switch (column) {
      case "email":
        regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(value);
      case "client_id":
      case "code_phone":
      case "code_postal":
        regex = /\d/;
        return regex.test(value);
      case "type_of_company":
        return !!(value === "persona_juridica" || value === "persona_natural");
      case "names":
      case "first_name":
      case "second_name":
      case "main_owner":
        regex = /^[ñíóáéúa-zA-Z ]+$/;
        return regex.test(value);
      case "role":
        return !!(value === "Legacy" || value === "Owner" || value === "Digital");
      case "birthdate":
        regex = /([12]\d{3}\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01]))/;
        validation = regex.test(value);
        age = calcularteAge(value.replace("///", "-"));
        return !!(age > 17 && validation);
      case "digital_stores":
        return !!(value.toLowerCase() === "true" || value.toLowerCase() === "false");
      default:
        return true;
    }
  };

  const calcularteAge = (bitrth_date) => {
    let currentDate = new Date();
    let birthDate = new Date(bitrth_date);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    let m = currentDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const retryCsv = () => {
    setViewTable(false);
  };
  const fnReturn = (file) => {
    setIsLoading(true);
    if (file.size > 10000000) {
      setErrorMessage(t("Migration.FileSize"));
      setShowError(true);
      return true;
    }
    let ext = file.name.split(".");
    ext = ext.slice(-1);
    if (ext[0] !== "csv") {
      setErrorMessage(t("Migration.ErrorExtension"));
      setShowError(true);
      setIsLoading(false);
      return true;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const jsonData = convertCSVToJson(text);
      for (const element of columnsCsv) {
        if (!(element in jsonData[0])) {
          setErrorMessage(t("Migration.MatchColumns"));
          setIsLoading(false);
          setShowError(true);
          return true;
        }
      }
      handleData(jsonData);
    };
    reader.readAsText(file);
    setIsLoading(false);
    return !showError;
  };
  const handleData = (json) => {
    if (json.length > 10) {
      setErrorMessage(t("Migration.MatchColumns"));
      setShowError(true);
      return true;
    }
    let dataTable = [];
    let dataErrors = [];
    let readyToMigrate = [];
    json.forEach((value) => {
      let keys = Object.keys(value);
      let listKeys = [];
      for (const element of keys) {
        let k = element;

        let result = validateColumn(k, value[k]);
        if (!result) {
          listKeys.push("\n " + t("Migration.columns." + k));
        }
        if (k === "digital_stores" && result) {
          value[k] = value[k].toLowerCase() === "true";
        }
      }
      let name = value.names + " " + value.first_name + " " + value.second_name;
      if (listKeys.length <= 0) {
        readyToMigrate.push(value);
        dataTable.push({
          name: name,
          phone: value.code_phone,
          email: value.email,
        });
      } else {
        dataErrors.push({
          name: name,
          phone: value.code_phone,
          email: value.email,
          errors: listKeys,
        });
      }
    });
    setData(dataTable);
    setDataErrors(dataErrors);
    setViewTable(true);
    setReadyMigrate(readyToMigrate);
  };
  const handleErrorClose = () => {
    setShowError(false);
  };

  const migrateMerchants = () => {
    setIsLoading(true);
    handleLoaderOpen();
    let encodedStr = jsonToBase64(JSON.stringify(readyMigrate));

    axiosPrivateRisk
      .post("/merchants/migrate", {
        file: encodedStr,
      })
      .then(() => {
        SnackBar("Se ha enviado un correo al cliente solicitando la información faltante", "success", "#E7EFF9");
        setIsLoading(false);
        handleLoaderClose();
        setViewTable(false);
      })
      .catch(() => {
        setErrorMessage("Existió un error al enviar las solicitudes. Inténtalo más tarde.");
        handleLoaderClose();
        setShowError(true);
      });
  };

  const jsonToBase64 = (object) => {
    let encoded = Base64.encode(object);
    return encoded;
  };

  return (
    <ThemeProvider theme={ThemeRisks}>
      <div className={"Drawer"}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: "10",
          }}
        >
          <div>
            <GenericBackdrop open={isLoading} />
          </div>
        </Box>
        {showError && <div style={overlayStyle} onKeyDown={(e) => e.stopPropagation()}></div>}
        <CardContent>
          <Sesion screen={"Merchants"} />
        </CardContent>

        {!viewTable ? (
          <div>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" style={{ fontFamily: "Nunito", fontWeight: "500" }}>
                  {t("Migration.MigrateUser")}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "50px" }}>
                <Typography
                  style={{
                    fontFamily: "Nunito",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "24px",
                    letter: "0.25px",
                  }}
                >
                  {t("Migration.Desc")}
                </Typography>
              </Grid>
            </Grid>
            <CardContent
              sx={{
                width: "88%",
                height: "49%",
                padding: "40px",
                borderRadius: "16px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                gap: "32px",
                position: "absolute",
                top: "222px",
                left: "144px",
                marginTop: "110px",
              }}
            >
              <ComponentDrag type=".csv" fnReturn={fnReturn} />
              <Box style={{ marginLeft: "-30px" }}>
                <Box
                  style={{
                    fontWeight: "600",
                    marginTop: "50px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letter: "0.25px",
                  }}
                >
                  <Typography>{t("Migration.FileSize")}</Typography>
                </Box>
              </Box>
            </CardContent>
          </div>
        ) : (
          <div>
            <Grid item xs={12}>
              <Typography
                style={{
                  fontFamily: "Nunito",
                  fontWeight: "500",
                  fontSize: "22px",
                  lineHeight: "22px",
                  letter: "0.25px",
                  marginLeft: "45px",
                }}
              >
                Revisa la Información del archivo
              </Typography>
            </Grid>
            <CardContent
              sx={{
                width: "88%",
                height: "80%",
                padding: "40px",
                borderRadius: "16px",
                backgroundColor: "#FFFFFF",
                gap: "32px",
                position: "absolute",
                top: "222px",
                left: "144px",
                marginTop: "20px",
                color: "#404A56",
              }}
            >
              <Box style={{ paddingBottom: "10px" }}>
                <Box
                  style={{
                    borderRadius: "50%",
                    float: "left",
                    padding: "4px",
                    marginTop: "-8px",
                    background: "#66BC6B",
                  }}
                >
                  <CheckIcon style={{ color: "white", fontWeight: "bold" }} />
                </Box>
                <Box style={{ fontWeight: "500", fontSize: "16px", paddingLeft: "5px" }}>
                  {t("Migration.TitleReady")}
                </Box>
              </Box>
              <BasicTable dataTable={data} dataColumns={columns} condition={condition} />
              <Box style={{ marginTop: "40px", paddingBottom: "10px" }}>
                <Box style={{ borderRadius: "50%", float: "left", marginTop: "-8px" }}>
                  <CancelIcon style={{ color: "red", fontWeight: "bold", fontSize: "37px" }} />
                </Box>
                <Box style={{ fontWeight: "500", fontSize: "16px", paddingLeft: "5px" }}>
                  {t("Migration.TitleError")}
                </Box>
              </Box>

              <BasicTable dataTable={dataErrors} dataColumns={columnsError} condition={condition} />
              <Box style={{ float: "right", marginTop: "20px" }}>
                <Button
                  data-testid="btn-retry"
                  style={{ border: "1px solid #FFC10E", borderRadius: "0px", height: "48px", color: "#404A56" }}
                  onClick={retryCsv}
                >
                  <img src={avatarsIcon} />
                  {t("Migration.ButtonMigrate")}
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    borderColor: "white",
                    fontWeight: "bold",
                    height: "48px",
                    fontSize: "12px",
                    background: "#FFC10E",
                    borderRadius: "0px",
                    color: "#404A56",
                  }}
                  variant="outlined"
                  data-testid="btn-migrate"
                  onClick={migrateMerchants}
                >
                  <img src={sendIcon} alt="Hello" />
                  {t("Migration.ButtonMigrate")}
                </Button>
              </Box>
            </CardContent>
          </div>
        )}
        <FileErrorAlert error={showError} onClose={handleErrorClose} message={errorMessage} />
      </div>
    </ThemeProvider>
  );
}
