import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import { Skeleton, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { SnackBar } from "../../components/utils/alert";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";
import GenericBackdrop from "../../utils/GenericBackdrop";

const RiskLevelInput = ({
  keyProp,
  index,
  errores,
  disabled,
  audience,
  audiences,
  ariskType,
  updatePercentageRisk,
}) => {
  const [t] = useTranslation("global");
  return (
    <TextField
      size="small"
      value={ariskType.percentage}
      data-testid="input-risk-percentage"
      error={errores.risk_percentages}
      label={t(`Risk.${ariskType.name}`)}
      helperText={t(`Risk.Max`) + " " + ariskType.maxPercentage + " " + "%"}
      disabled={disabled || index != audiences.length - 1 || audience.id_class == ""}
      InputProps={{
        sx: {
          borderRadius: 15,
          maxWidth: "160px",
        },
        endAdornment: (
          <InputAdornment position="end">
            <PercentIcon />
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        let value = event.target.value;
        value = value.replace(/[^0-9]/g, "");
        updatePercentageRisk(value, keyProp);
      }}
    />
  );
};

const RiskLevelSkeleton = () => {
  return (
    <Skeleton
      variant="rectangular"
      sx={{
        width: "160px",
        height: "40px",
        borderRadius: "20px",
        marginRight: "12px",
        marginTop: "16px",
        marginBottom: "25px",
      }}
    />
  );
};

const Audience = ({
  audience,
  audiences,
  setAudiences,
  index,
  classesBase,
  disabled,
  shipmentNumber,
  initialAviableClients,
  aviableClients,
  setClientsUsedBatches,
  setAviableClients,
  riskTypes,
  baseSelected,
  setBaseSelected,
  setBaseSelectedName,
  setInitialAviableClients,
  setShipmentNumber,

  setInitialRisks,
  endpoint,
  loading,
}) => {
  const [t] = useTranslation("global");
  const axiosPrivateRisk = useAxiosPrivate(ApisEnum.Risk);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [valueInput, setValueInput] = useState('--');

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  useEffect(() => {
    if (audience.classtxt !== '') {
      updateclientsAviable(audience.classtxt)
    }
  }, [shipmentNumber]);

  function updateclientsAviable(classes) {
    if (parseInt(audience.clientsAviable[classes].users_available) < parseInt(shipmentNumber)) {
      setValueInput(parseInt(0))
    } else {
      setValueInput(parseInt(parseInt(audience.clientsAviable[classes].users_available) / parseInt(shipmentNumber)))
    }
  }

  function calculateClientsAviable() {
    const newObject = {};

    for (let key in audience.clientsAviable) {
      if (audience.clientsAviable.hasOwnProperty(key)) {
        if (key == audience.classtxt) {
          newObject[key] = {
            users_available: audience.clientsAviable[key].users_available - parseInt(audience.amount_clients) * parseInt(shipmentNumber)
          };
        } else {
          newObject[key] = {
            users_available: audience.clientsAviable[key].users_available
          };
        }
      }
    }

    return newObject;
  }

  function deepCopyObject(object) {
    let copy = Array.isArray(object) ? [] : {};
  
    for (let key in object) {
      if (typeof object[key] === 'object' && object[key] !== null) {
        copy[key] = deepCopyObject(object[key]);
      } else {
        copy[key] = object[key];
      }
    }
  
    return copy;
  }

  function calculateRiskAudience() {
    let risk_copy = deepCopyObject(audience['risksByClass'])

    for (const risk of audience.risk_percentages) {
      if (risk.percentage != '') {
        risk_copy[audience.classtxt][risk.name] = audience['risksByClass'][audience.classtxt][risk.name] - ((risk.percentage / 100) * (parseInt(audience['amount_clients'])*shipmentNumber))
      }
    }
    return risk_copy
  }

  const addNewAudience = () => {
    let amount = parseInt(parseInt(aviableClients) / parseInt(shipmentNumber));
    const newAudience = {
      classtxt: "",
      inputClass: "",
      id_class: "",
      amount_clients: "",
      maxAviableClients: amount,
      clientsAviable: calculateClientsAviable(),
      risksByClass: calculateRiskAudience(),
      risk_percentages: [],
    };
    console.log(audience)
    console.log(newAudience)
    riskTypes.forEach((risk) => {
      newAudience.risk_percentages.push({
        id_risk_level: risk.id_risk_level,
        name: risk.name,
        maxPercentage: "--",
        percentage: "",
      });
    });

    const nuevasAudiences = [...audiences, newAudience];
    setAudiences(nuevasAudiences);
  };

  const validateAudience = (doCreate) => {
    if (index == audiences.length - 1) {
      const nuevosErrores = {
        id_class: audience.id_class == "",
        amount_clients: audience.amount_clients == "",
        risk_percentages: false,
      };
      let sum = 0;
      audience.risk_percentages.forEach((risk) => {
        let val = risk.percentage;
        if (val != "") {
          sum = parseInt(val) + sum;
        }
      });

      if (!(sum >= 95 && sum <= 100)) {
        nuevosErrores.risk_percentages = true;
      }
      setErrores(nuevosErrores);
      if (
        nuevosErrores.id_class ||
        nuevosErrores.amount_clients ||
        nuevosErrores.risk_percentages ||
        parseInt(aviableClients) === 0 ||
        parseInt(parseInt(aviableClients) / parseInt(shipmentNumber)) <= 0
      ) {
        return;
      }

      if (doCreate) {
        addNewAudience();
      }
    }
  };

  const [errores, setErrores] = React.useState({
    id_class: false,
    amount_clients: false,
  });

  const addAudienceRisk = (type, value) => {

    let customers = audience.clientsAviable[audience.classtxt].users_available
    if (value > 0) {
      customers = value*shipmentNumber
    }
    let newAudiences = [...audiences];
    newAudiences[index][type] = [];
    riskTypes.forEach((risk) => {

      let amount = audience['risksByClass'][audience.classtxt][risk.name] || 0;
      let result = Math.floor((amount / customers) * 100);
      if (result > 100) result = 100;

      newAudiences[index][type].push({
        id_risk_level: risk.id_risk_level,
        name: risk.name,
        maxPercentage: result,
        percentage: "",
      });
    });

    setAudiences(newAudiences);
  };


  const onInputAmountClients = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9]/g, "");
    if (value != "" && index == 0 && value <= valueInput) {
      addAudience(value, "amount_clients");
      let totaUsedClients = parseInt(value) * parseInt(shipmentNumber);

      setAviableClients(initialAviableClients - totaUsedClients);
      let val = sumAmountClients();
      setClientsUsedBatches(val);

      addAudienceRisk("risk_percentages", value);
    } else if (value != "" && index > 0 && value <= valueInput) {
      addAudience(value, "amount_clients");

      let new_total = sumAmountClients();
      setAviableClients(initialAviableClients - new_total);
      setClientsUsedBatches(new_total);
      addAudienceRisk("risk_percentages", value);
    } else if (value == "" && index == 0) {
      addAudience("", "amount_clients");
      setAviableClients(initialAviableClients);
      setClientsUsedBatches(0);
      addAudienceRisk("risk_percentages", 0);
    } else if (value == "" && index > 0) {
      addAudience("", "amount_clients");
      setAviableClients(initialAviableClients - sumAmountClients());
      setClientsUsedBatches(sumAmountClients);
      addAudienceRisk("risk_percentages", 0);
    }
  };

  const onInputClass = (name, _) => {
    if (name != null) {
      addAudienceRisk("risk_percentages", 0);
    }
  };

  const addAudience = (text, field) => {
    const newAudiences = [...audiences];
    newAudiences[index][field] = text;
    setAudiences(newAudiences);
  };

  const formatNumber = (number) => {
    if (number == "--") {
      return "--";
    } else if (number != "") {
      let dollarUSLocale = Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
      });
      return dollarUSLocale.format(parseInt(number));
    }
  };

  const sumAmountClients = () => {
    let total = 0;
    audiences.forEach((audience) => {
      if (audience.amount_clients != "") total += parseInt(audience.amount_clients) * parseInt(shipmentNumber);
    });
    return total;
  };

  const onDeletesumAmountClients = (audiences) => {
    let total = 0;
    audiences.forEach((audience) => {
      if (audience.amount_clients != "") {
        total += parseInt(audience.amount_clients) * parseInt(shipmentNumber);
      }
    });
    return total;
  };

  const updatePercentageRisk = (value, key) => {
    if (parseInt(value) <= parseInt(audiences[index]["risk_percentages"][key]["maxPercentage"])) {
      let newAudiences = [...audiences];
      newAudiences[index]["risk_percentages"][key]["percentage"] = parseInt(value);
      setAudiences(newAudiences);
      validateAudience(false);
    } else {
      let newAudiences = [...audiences];
      newAudiences[index]["risk_percentages"][key]["percentage"] = "";
      setAudiences(newAudiences);
      validateAudience(false);
    }
  };

  const deleteAudience = (index_delete) => {
    const newAudiences = [...audiences];
    let count_delete = 0;
    if (audiences[index_delete].amount_clients != "") {
      count_delete = parseInt(audiences[index_delete].amount_clients);
    }

    setAviableClients(aviableClients + count_delete * shipmentNumber);
    newAudiences.splice(index_delete, 1);
    setAudiences(newAudiences);
    let val = onDeletesumAmountClients(newAudiences);
    setClientsUsedBatches(val);
  };

  const createBatches = async () => {
    let body = {
      shipment_number: parseInt(shipmentNumber),
      base: baseSelected,
      audiences: audiences,
    };
    await sendBatches(body);
  };

  const sendBatches = async (body) => {
    handleLoaderOpen();
    axiosPrivateRisk
      .post(endpoint, body)
      .then((response) => {
        handleLoaderClose();

        let data = response.data;
        if (data.status == 200) {
          SnackBar(t(`Risk.BatchCreatedSuccess`), "success", "#EDF7EF");
          navigate("/Risk/Batch");
          setAudiences([
            {
              classtxt: "",
              inputClass: "",
              id_class: "",
              maxAviableClients: "",
              amount_clients: "",
              risk_percentages: [],
            },
          ]);
          setBaseSelected("");
          setBaseSelectedName("");
          setInitialAviableClients(0);
          setAviableClients(0);
          setClientsUsedBatches(0);
          setShipmentNumber(0);
          setInitialRisks([]);
        }
      })
      .catch((_) => {
        SnackBar(t(`Risk.BatchCreatedError`), "error", "#F8E7E9");
        handleLoaderClose();
      });
  };

  const checkAudiences = () => {
    let check = true;
    audiences.forEach((audience) => {
      let checkRisk = checkAudiencesRisk(audience);
      if (audience.id_class == "" || audience.maxAviableClients == "" || audience.amount_clients == "" || !checkRisk) {
        check = false;
      }
    });
    return check;
  };

  const checkAudiencesRisk = (audience) => {
    let check = true;
    let sum = 0;
    audience.risk_percentages.forEach((risk) => {
      if (risk.percentage != "") {
        let val = risk.percentage;
        if (val != "") {
          sum = parseInt(val) + sum;
        }
      }
    });
    if (sum < 95) {
      check = false;
    }
    return check;
  };

  function getClass() {
    if (Object.keys(audience.clientsAviable).length !== 0) {
      const resultFilter = classesBase.filter(item => {
        const name = item.name;
        return audience.clientsAviable[name].users_available !== 0;
      });
      return resultFilter
    }
    return classesBase
  }

  return (
    <>
      <GenericBackdrop open={loader} />

      <Grid item container spacing={2}>
        <Grid item lg={6} xs={6} container marginBottom={1} direction="row" justifyContent="start" alignItems="start">
          <Typography variant="h4">{t(`Risk.Audience`) + " " + (index + 1)}</Typography>
        </Grid>
        {audiences.length > 1 && (
          <Grid item lg={6} xs={6} container marginBottom={1} direction="row" justifyContent="end" alignItems="end">
            <DeleteOutlinedIcon
              onClick={() => deleteAudience(index)}
              style={{ cursor: "pointer" }}
              name="deleteAudience"
            ></DeleteOutlinedIcon>
          </Grid>
        )}
      </Grid>

      <Divider></Divider>
      <Grid item container spacing={2}>
        <Grid
          item
          lg={6}
          xs={6}
          container
          marginTop={9}
          paddingRight={5}
          direction="row"
          justifyContent="start"
          alignItems="start"
        >
          <Grid container spacing={1} gap={2}>
            <Grid item={false} xs>
              <Autocomplete
                disabled={disabled || index != audiences.length - 1}
                disableClearable
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 15,
                    marginBottom: 1,
                  },
                }}
                onChange={(event, newValue) => {
                  if (newValue != undefined) {
                    addAudience(newValue.name, "classtxt");
                    addAudience(newValue.id, "id_class");
                    onInputClass(newValue.name, newValue.id);
                    updateclientsAviable(newValue.name)
                  }
                }}
                inputValue={audience.inputClass}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onInputChange={(event, newValue) => {
                  if (newValue != undefined) {
                    addAudience(newValue, "inputClass");
                  }
                }}
                data-testid="autocomplete"
                options={getClass()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(`Risk.Class`)}
                    sx={{ minWidth: 50, maxWidth: 250 }}
                    error={errores.id_class}
                  />
                )}
                size="small"
              />
            </Grid>
            <Grid item={false} xs>
              <TextField
                data-testid="amount_clients"
                error={errores.amount_clients}
                disabled={disabled || index != audiences.length - 1 || audience.id_class == ""}
                InputProps={{ sx: { borderRadius: 15 } }}
                sx={{ minWidth: 50, maxWidth: 250 }}
                InputLabelProps={{
                  sx: { "& Focused": { color: "black" } },
                }}
                size="small"
                label={t(`Risk.AmountCostumers`)}
                value={audience.amount_clients}
                onChange={onInputAmountClients}
                helperText={
                  t(`Risk.Max`) + " " + `${formatNumber(valueInput)}` + " " + t(`Risk.Clients`)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={6} container marginTop={2} direction="row" justifyContent="start" alignItems="start">
          <Typography variant="subtitle1" sx={{ marginLeft: "-35px" }}>
            {t(`Risk.RiskLevel`)}
          </Typography>
          <Grid container spacing={5} marginTop={1}>
            {loading
              ? [...Array(5).keys()].map((v) => <RiskLevelSkeleton key={v} />)
              : audience.risk_percentages.map((ariskType, key) => (
                <Grid item={false} xs marginTop={1} key={key}>
                  <RiskLevelInput
                    keyProp={key}
                    index={index}
                    audience={audience}
                    audiences={audiences}
                    ariskType={ariskType}
                    disabled={disabled}
                    errores={errores}
                    updatePercentageRisk={updatePercentageRisk}
                  />
                </Grid>
              ))}
          </Grid>
          <Typography variant="subtitle2" sx={{ marginLeft: "-35px" }}>
            {t(`Risk.LargeNote`)}
          </Typography>
        </Grid>
      </Grid>
      {index === audiences.length - 1 && (
        <>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Button
              onClick={() => validateAudience(true)}
              data-testid="add-audience"
              sx={{ color: "#CFD0DF", marginTop: "16px" }}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t(`Risk.AddAudience`)}
            </Button>
          </Grid>
          <Grid container direction="row" justifyContent="end" alignItems="end">
            <Button variant="blank" data-testid="cancel-batch" onClick={() => navigate("/Risk/Batch")}>
              {t(`Risk.Cancel`)}
            </Button>
            <Button
              onClick={createBatches}
              data-testid="create-batch"
              disabled={shipmentNumber === "" || !checkAudiences()}
              variant={shipmentNumber === "" || !checkAudiences() ? "disabled" : "solid"}
            >
              {t(`Risk.CreateBatch`)}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default Audience;
