import useSWR from "swr";

import ApisEnum from "../../utils/enums/apis.enum";

import useAxiosPrivate from "../useAxiosPrivate";

const useProductList = (only_name) => {
  const axiosClient = useAxiosPrivate(ApisEnum.Backoffice);

  const productListFetcher = async (url) => {
    const { data } = await axiosClient.get(url);
    return data;
  };

  const { data, isLoading, error } = useSWR(`/products?only_name=${only_name ?? false}`, productListFetcher);

  return {
    isLoading,
    productList: data,
    error,
  };
};

export default useProductList;
