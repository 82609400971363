import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApisEnum from "../../utils/enums/apis.enum";
import GenericBackdrop from "../../utils/GenericBackdrop";

const styleTextField = {
  marginTop: "15px",
};

const CreateBatchHeader = ({
  shipmentNumber,
  audiences,
  aviableClients,
  baseSelectedName,
  baseSelected,
  bases,
  classes,
  initialAviableClients,
  setBaseSelectedName,
  setShipmentNumber,
  setAviableClients,
  setBaseSelected,
  setInitialAviableClients,
  setAudiences,
  setClientsUsedBatches,
  setInitialRisks,
  endpoint,
  setBases,
  setRiskTypes,
  setClasses,
  setClassesBase,
  baseendpoint,
  setLoading,
}) => {
  const [t] = useTranslation("global");

  const axiosPrivateRisk = useAxiosPrivate(ApisEnum.Risk);

  const [loader, setLoader] = useState(false);

  const handleLoaderOpen = () => setLoader(true);
  const handleLoaderClose = () => setLoader(false);

  function filterClasses(data, baseName) {
    const nameClasses = Object.keys(data[baseName]);

    return classes.filter(item => nameClasses.includes(item.name));
  }

  useEffect(async () => {
    const getInfoBatch = async () => {
      setLoading(true);
      axiosPrivateRisk
        .get(endpoint, {
          params: {
            base: baseendpoint,
          },
        })
        .then((response) => {
          let info = response.data;
          setClasses(info.class_risk);
          setRiskTypes(info.risk_level);
          setBases(info.type_base);
          setAviableClients(0);

          let newAudiences = [...audiences];
          newAudiences[0]["risk_percentages"] = [];
          info.risk_level.forEach((risk) => {
            newAudiences[0]["risk_percentages"].push({
              id_risk_level: risk.id_risk_level,
              name: risk.name,
              maxPercentage: "--",
              percentage: "",
            });
          });
          setAudiences(newAudiences);
          setLoading(false);
        })
        .catch((error) => {
          setAviableClients(0);
          console.log(error);
        });
    };

    await getInfoBatch();
  }, []);

  const onBaseSelectedChange = async (value) => {
    if (value != "") {
      setBaseSelectedName(value);
      await getOffersAvialable(value);
    }
  };

  const inputShipmentNumber = (event) => {
    let value = event.target.value;
    let number = parseInt(value);
    let amount = parseInt(initialAviableClients / number);
    if (value != "") {
      value = value.replace(/[^0-9]/g, "");
      if (amount > 0) {
        setShipmentNumber(value);
        let newAudiences = [...audiences];
        newAudiences[0].maxAviableClients = amount;
      }
    } else {
      setShipmentNumber(value);
      let newAudiences = [...audiences];
      newAudiences[0].maxAviableClients = "--";
      setAudiences(newAudiences);
    }
  };

  const countClientsAvailable = (offers_available, used_offers) => {
    let total_offers = offers_available.reduce((a, b) => a + b.count, 0);
    let total_used_offers = used_offers.reduce((a, b) => a + b.quantity_per_risk, 0);
    let total = total_offers - total_used_offers;

    return total;
  };

  const countClientsAviableByRisk = (offers_available, used_offers) => {
    let risks = [];

    used_offers.forEach((offer) => {
      let findRisk = offers_available.find((item) => item.risk_level == offer.name);
      let availableRisk = 0;

      if (findRisk) {
        availableRisk = findRisk.count - offer.quantity_per_risk;
      }

      let risk = {
        name: offer.name.replace("_", ""),
        amount: availableRisk,
        id_risk_level: offer.id_risk_level,
      };
      risks.push(risk);
    });

    return risks;
  };

  function convertRiskArrayToJsonRisks(risks) {
    const result = {};
 
    risks.forEach(item => {
       const className = item.class;
       delete item.class;
 
       if (!result[className]) {
          result[className] = {};
       }
 
       Object.assign(result[className], item);
    });
 
    return result;
 }

  const getOffersAvialable = async (value) => {
    handleLoaderOpen();
    axiosPrivateRisk
      .get(endpoint, {
        params: {
          base: value,
        },
      })
      .then((response) => {
        let data = response.data;
        handleLoaderClose();
        let total_offers = countClientsAvailable(data.offers_available, data.used_offers);
        let risks = countClientsAviableByRisk(data.offers_available, data.used_offers);

        if (total_offers > 0) {
          setInitialAviableClients(total_offers);
          setAviableClients(total_offers);
          setClientsUsedBatches(0);
          setShipmentNumber(1);
          setInitialRisks(risks);
          setClassesBase(filterClasses(data.class_config, value))
          let newAudiences = [...audiences];
          newAudiences[0]["maxAviableClients"] = parseInt(total_offers);
          newAudiences[0]["clientsAviable"] = data.class_config[value]
          newAudiences[0]["risksByClass"] = convertRiskArrayToJsonRisks(data.risk_config)
          setAudiences(newAudiences);
        } else {
          setInitialAviableClients(0);
          setAviableClients(0);
          setClientsUsedBatches(0);
          setInitialRisks([]);
        }
      })
      .catch((error) => {
        setInitialAviableClients(0);
        setAviableClients(0);
        setClientsUsedBatches(0);
        setInitialRisks([]);
        handleLoaderClose();
        console.log(error);
      });
  };

  return (
    <>
      <GenericBackdrop open={loader} />

      <Grid item container spacing={2}>
        <Grid
          item
          lg={6}
          md={6}
          xs={11}
          container
          marginTop={1}
          paddingRight={5}
          direction="row"
          justifyContent="start"
          alignItems="start"
        >
          <Grid container spacing={1} gap={2}>
            <Grid item={false} xs>
              <Typography variant="subtitle1">{t(`Risk.Base`)}</Typography>
              <Autocomplete
                disabled={audiences.length > 1}
                disableClearable
                data-testid="autocomplete-base"
                value={baseSelectedName}
                name="baseSelected"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 15,
                  },
                }}
                onChange={async (event, newValue) => {
                  if (newValue != undefined) {
                    await onBaseSelectedChange(newValue);
                  }
                }}
                inputValue={baseSelected}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue != undefined) {
                    setBaseSelected(newInputValue);
                  }
                }}
                options={bases}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(`Risk.BaseSelected`)}
                    sx={{ minWidth: 50, maxWidth: 250, marginTop: "15px" }}
                  />
                )}
                size="small"
              />
            </Grid>
            <Grid item={false} xs>
              <Typography variant="subtitle1">{t(`Risk.ShipmentToSchedule`)}</Typography>
              <TextField
                disabled={
                  audiences.length > 1 || audiences[0].amount_clients != "" || baseSelected == "" || aviableClients == 0
                }
                size="small"
                data-testid="input-shipment-number"
                style={styleTextField}
                value={shipmentNumber}
                onChange={inputShipmentNumber}
                id="filled-basic"
                label={t(`Risk.ShipmentNumber`)}
                sx={{ minWidth: "184px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateBatchHeader;
