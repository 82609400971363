import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { Card } from "@mui/material";

import useKycInfo from "../../../hooks/backoffice/useKycInfo";

const verifyValue = (value) => {
  return value || '--';
};

export default function Kyc({ clientId }) {
  const [t] = useTranslation("global");
  const { kycInfo, isLoading } = useKycInfo(clientId);

  return (
    <div>
      <h4 style={{ fontWeight: "500", fontSize: "22px", color: "#404A56" }}>{t("Backoffice.Details.PersonalData")}</h4>
      <Card
        sx={{
          padding: "32px",
          marginTop: "40px",
          boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
        }}
      >
        <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.Document")}
            </label>
            <p style={{ fontWeight: "400", fontSize: "16px", color: "#343C46" }}>
              {isLoading ? <Skeleton width={150} /> : verifyValue(kycInfo?.document)}
            </p>
          </div>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.CivilStatus")}
            </label>
            <p style={{ fontWeight: "400", fontSize: "16px", color: "#343C46" }}>
              {isLoading ? <Skeleton width={150} /> : verifyValue(kycInfo?.civilStatus)}
            </p>
          </div>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.PartnersActivity")}
            </label>
            <p style={{ fontWeight: "400", fontSize: "16px", color: "#343C46" }}>
              {isLoading ? <Skeleton width={150} /> : verifyValue(kycInfo?.partnerActivity)}
            </p>
          </div>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.Birthplace")}
            </label>
            <p style={{ fontWeight: "400", fontSize: "16px", color: "#343C46" }}>
              {isLoading ? <Skeleton width={150} /> : verifyValue(kycInfo?.birthplace)}
            </p>
          </div>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.PartnersName")}
            </label>
            <p style={{ fontWeight: "400", fontSize: "16px", color: "#343C46" }}>
              {isLoading ? <Skeleton width={150} /> : verifyValue(kycInfo?.partnerName)}
            </p>
          </div>
          <div>
            <label
              style={{
                fontWeight: "600",
                fontSize: "14px",
                color: "#708195",
                margin: "0",
              }}
            >
              {t("Backoffice.Kyc.Debit")}
            </label>
            <p
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: kycInfo?.debit ? "#66BC6B" : "#FF0000",
              }}
            >
              {isLoading ? <Skeleton width={150} /> : kycInfo?.debit ? "Activo" : "Inactivo"}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
