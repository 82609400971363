export const useSessionStorage = (key) => {
  return sessionStorage.getItem(key);
};

export const deleteKeySessionStorage = (key) => {
  return sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => {
  return sessionStorage.clear();
};

export const findKeyInSessionStorage = (key) => {
  const keys = Object.keys(sessionStorage);
  const searchKey = keys.find((keyInStorage) => keyInStorage.includes(key));
  return searchKey;
};
