export const shouldShowMoratoriumData = (loan) => {
  // Check if loan is null, undefined, or not an object
  if (!loan || typeof loan !== 'object') return false;

  const moratoriumDays = loan.moratoriumDays;

  // Check if moratoriumDays is one of the invalid values
  const invalidValues = ["", null, undefined, "0", "--", "$0.00"];
  if (invalidValues.includes(moratoriumDays)) {
    return false;
  }

  // If none of the invalid values match, return true
  return true;
};
