import { axiosPrivate } from "../../../api/axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ErrorIcon from "@mui/icons-material/Error";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";

import { Alert } from "../../utils/alert";

import AggridTable from "../../aggrid/aggridTable";
import { DynamicAction } from "../../aggrid/dynamicAction";
import { CleanFilterButton } from "../../commons/cleanFilterButton";
import { FilterButton } from "../../commons/filterButton";
import GenericBackdrop from "../../commons/GenericBackdrop";
import Search from "../../commons/search";

const UserProfiles = ({ client_id }) => {
  const [t] = useTranslation("global");

  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(true);
  const [dataUsers, setDataUsers] = useState([]);

  const [dataBackUp, setDataBackUp] = useState([]);
  const [columnsSearch, setColumnsSearch] = useState([]);

  const [store, setStore] = useState(null);
  const [dataStores, setDataStores] = useState([]);
  const [role, setRole] = useState(null);
  const [dataRoles, setDataRoles] = useState([]);
  const [status, setStatus] = useState(null);

  const inputSearch = useRef(null);
  const CELL_LEFT = "ag-cell-left";
  const CELL_CENTER = "ag-cell-center";
  const HEADER_CENTER = "ag-header-center";
  const columnsDetails = {
    headerName: "",
    field: "",
    flex: 1,
    cellClass: CELL_LEFT,
  };

  const tableColumns = [
    {
      ...columnsDetails,
      headerName: t("Owners.UserProfiles.Table.Name"),
      field: "name",
    },
    {
      ...columnsDetails,
      headerName: t("Owners.UserProfiles.Table.Email"),
      field: "email",
    },
    {
      ...columnsDetails,
      headerName: t("Owners.UserProfiles.Table.Store"),
      field: "store",
    },
    {
      ...columnsDetails,
      headerName: t("Owners.UserProfiles.Table.Role"),
      field: "role",
    },
    {
      ...columnsDetails,
      headerName: t("Owners.UserProfiles.Table.Date"),
      field: "date",
    },
    {
      headerName: t("Owners.UserProfiles.Table.Status"),
      field: "status",
      cellRendererFramework: DynamicAction,
      cellRendererParams: { icon: "DeviceStatus" },
      flex: 1,
      headerClass: HEADER_CENTER,
      cellClass: CELL_CENTER,
    },
  ];

  useEffect(() => {
    getUsers({ with_catalogs: true });
  }, []);

  const getUsers = async (params) => {
    setLoader(true);

    let url = `merchant/${client_id}/users`;

    await axiosPrivate
      .get(url, { params: { ...params } })
      .then((response) => {
        setColumnsSearch(response.data.search);

        setDataBackUp(response.data.results);
        setDataUsers(response.data.results);

        if (params.with_catalogs) {
          setDataStores(response.data.stores);
          setDataRoles(response.data.roles);
        }

        setReload(true);
      })
      .catch(() => {
        Alert(t("General.Message.ErrorService"), "error");
      });

    setLoader(false);
  };

  const menuItem = (value) => {
    return (
      <MenuItem key={value.name} value={value.name}>
        {value.name}
      </MenuItem>
    );
  };

  const cleanFilter = () => {
    if (inputSearch.current?.value || store !== null || role !== null || status !== null) {
      if (inputSearch.current?.value) inputSearch.current.value = "";

      setStore(null);
      setRole(null);
      setStatus(null);

      getUsers({ with_catalogs: false });
    }
  };

  const filterData = () => {
    if (store !== null || role !== null || status !== null) {
      if (inputSearch.current?.value) inputSearch.current.value = "";

      getUsers({ with_catalogs: false, store, role, status });
    }
  };

  return (
    <Fragment>
      <GenericBackdrop open={loader} />
      <Search
        backUp={dataBackUp}
        setSearch={setDataUsers}
        columnsSearch={columnsSearch}
        setIsSearch={setReload}
        placeholder={t("Owners.UserProfiles.Search.Placeholder")}
        setReload={setReload}
        inputRefSearch={inputSearch}
      />
      <Card
        sx={{
          margin: "1vh 0",
          paddingTop: "5vh",
          paddingBottom: "1vh",
          backgroundColor: "#EFF1F399",
        }}
      >
        <Stack direction="row" width="100%" spacing={1} justifyContent="start">
          <Stack direction="row" width="550vh" spacing={3} justifyContent="start" sx={{ marginInlineStart: 2 }}>
            <TextField
              id="store"
              label={t("Owners.UserProfiles.Filter.Store")}
              select
              value={store || ""}
              onChange={(event) => {
                setStore(event.target.value);
              }}
              sx={{ width: "180px" }}
              fullWidth
              size="small"
              inputProps={{ "data-testid": "store" }}
              InputLabelProps={{ style: { color: "#00377D" } }}
            >
              {dataStores.map((value) => menuItem(value))}
            </TextField>
            <TextField
              id="role"
              label={t("Owners.UserProfiles.Filter.Role")}
              select
              value={role || ""}
              size="small"
              inputProps={{ "data-testid": "role" }}
              onChange={(event) => {
                setRole(event.target.value);
              }}
              sx={{ width: "160px" }}
              InputLabelProps={{ style: { color: "#00377D" } }}
            >
              {dataRoles.map((value) => menuItem(value))}
            </TextField>
            <Box sx={{ display: "flex" }}>
              <FormControl sx={{ mt: -3 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ weight: 500 }}>
                  {t("Owners.UserProfiles.Filter.Status")}
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="status"
                        checked={status || false}
                        onChange={(event) => {
                          setStatus(event.target.checked);
                        }}
                        name="status"
                        icon={<ErrorOutlineIcon />}
                        checkedIcon={<ErrorIcon />}
                      />
                    }
                    label={t("Owners.UserProfiles.Filter.Blocked")}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Stack>
          <Stack direction="row" width="100%" spacing={3} justifyContent="end">
            <CleanFilterButton filterFnc={cleanFilter} label={t("Filter.Clean")} />
            <FilterButton filterFnc={filterData} label={t("Filter.Filter")} />
          </Stack>
        </Stack>
      </Card>
      <AggridTable
        columnDefs={tableColumns}
        pagination={true}
        height={400}
        width={"100%"}
        rowdata={dataUsers}
        showsearch={false}
        reload={reload}
        setReload={setReload}
      />
    </Fragment>
  );
};

UserProfiles.propTypes = {
  client_id: PropTypes.string,
};

export default UserProfiles;
