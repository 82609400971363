import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RemoveRedEye } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import useClients from "../../hooks/backoffice/useClients";
import debounce from "../../utils/debounce";

import { SearchInput } from "../commons/backoffice/searchInput";

export default function ClientsList({ disableVirtualization = false, search = "" }) {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [searchState, setSearch] = React.useState(search);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const { clients, isLoading, onPageChange, onSearchClients, totalItems } = useClients({
    limit: paginationModel.pageSize,
    pag: paginationModel.page,
  });

  const handleSearchDebounced = debounce((value) => {
    if (value) {
      setSearch(value);
    } else {
      setSearch("");
    }
  });

  React.useEffect(() => {
    onSearchClients(searchState);
  }, [searchState]);

  const renderActions = (params) => {
    const { id } = params.row;
    return (
      <IconButton aria-label="action-list" onClick={() => navigate(`Client/${id}`)}>
        <RemoveRedEye
          sx={{
            color: "#404A56",
          }}
        />
      </IconButton>
    );
  };

  const columns = [
    {
      field: "name",
      headerName: t("Backoffice.SearchClient.Table.Name"),
      flex: 0.32,
      valueGetter: (params) => {
        return params.value ? params.value : "-";
      },
    },
    {
      field: "transaction",
      headerName: t("Backoffice.SearchClient.Table.LastTransaction"),
      flex: 0.14,
      valueGetter: (params) => {
        return params.value ? params.value : "-";
      },
    },
    {
      field: "country",
      headerName: t("Backoffice.SearchClient.Table.Country"),
      flex: 0.1,
      valueGetter: (params) => {
        return params.value ? params.value : "-";
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.06,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderActions,
    },
  ];

  const rows = clients.map((client) => {
    const { id, name, email, country, transaction, accountholderkey } = client;
    return {
      id,
      name,
      email,
      country,
      accountholderkey,
      transaction: new Date(transaction).toLocaleDateString(),
    };
  });

  return (
    <>
      <label style={{ fontWeight: "400", fontSize: "14px", color: "#708195" }}>
        {t("Backoffice.SearchClient.Label")}
      </label>
      <h2 style={{ fontWeight: "700", fontSize: "28px" }}>{t("Backoffice.SearchClient.Title")}</h2>
      <Box
        sx={{
          width: "100%",
          height: 115,
          backgroundColor: "#EEF0F2",
          borderRadius: "16px",
          marginTop: "32px",
          padding: "16px 32px",
        }}
      >
        <label
          style={{
            fontWeight: "500",
            fontSize: "14px",
            color: "#4C5866",
            display: "block",
          }}
          htmlFor="search-client"
        >
          {t("Backoffice.SearchClient.Search")}
        </label>
        <SearchInput
          id="search-client"
          placeholder={t("Backoffice.SearchClient.SearchPlaceholder")}
          onSubmit={onSearchClients}
          onChange={handleSearchDebounced}
          role="search-client"
        />
      </Box>
      {searchState && searchState.length > 2 && (
        <Box sx={{ width: "100%" }}>
          <DataGrid
            sx={{
              padding: "16px",
              height: rows.length === 0 ? "300px" : null,
              border: "none",
              "&>.MuiDataGrid-main": {
                "& div div div div >.MuiDataGrid-cell": {
                  borderBottom: "none",
                },
              },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#EEF0F2",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              },
            }}
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={totalItems}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={(newPagination) => {
              setPaginationModel(newPagination);
              onPageChange(newPagination.page);
            }}
            disableVirtualization={disableVirtualization}
            localeText={{
              noResultsOverlayLabel: t("Backoffice.SearchClient.NotFound"),
              noRowsLabel: t("Backoffice.SearchClient.NotFound"),
            }}
          />
        </Box>
      )}
    </>
  );
}
