import { axiosPrivate, axiosPrivateBackoffice, axiosPrivateRisk } from "../api/axios";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";

import ApisEnum from "../utils/enums/apis.enum";

import useRefreshToken from "./useRefreshToken";
import { findKeyInSessionStorage, useSessionStorage } from "./useSessionStorage";

/**
 * A custom hook that returns an instance of axios with private access.
 * @param {ApisEnum} api - The API endpoint to be used. You can use Risk, Backoffice or Default.
 * If no provided, Default will be used.
 * @returns {Object} - An instance of axios with private access.
 */
const useAxiosPrivate = (api) => {
  if (api === undefined || api === null) {
    api = ApisEnum.Default;
  }
  const refresh = useRefreshToken();
  const useAuth = useAuthUser();
  const auth = useAuth();
  const key = findKeyInSessionStorage("accesstoken");
  const accessToken = useSessionStorage(key);
  useEffect(() => {
    const axiosInstance = getAxiosInstance(api);
    const requestIntercept = getRequestInterceptor(api, auth, accessToken);
    const responseIntercept = getReponseInterceptor(api, refresh);

    return () => {
      if (api == ApisEnum.Backoffice) {
        axiosInstance.interceptors.request.eject(requestIntercept);
        return;
      }

      if (api == ApisEnum.Risk) {
        axiosInstance.interceptors.request.eject(requestIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
        return;
      }

      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [accessToken, auth, refresh, api]);

  return getAxiosInstance(api);
};

const getRequestInterceptor = (api, auth, accessToken) => {
  const axiosInstance = getAxiosInstance(api);

  if (api == ApisEnum.Backoffice) {
    return;
  }

  if (api == ApisEnum.Risk) {
    return axiosInstance.interceptors.request.use(
      (config) => {
        if (accessToken && accessToken !== undefined) {
          const token = JSON.parse(accessToken)["secret"];
          config.headers["Authorization"] = token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }

  return axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = auth?.token;
      }
      if (!config.headers["mts-token"]) {
        config.headers["mts-token"] = auth?.homeAccountId;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

const getReponseInterceptor = (api, refresh) => {
  const axiosInstance = getAxiosInstance(api);
  if (api === ApisEnum.Risk) {
    return axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        return Promise.reject(error);
      }
    );
  }

  return axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      debugger;
      const prevRequest = error?.config;
      if (error?.response?.status === 403 && !prevRequest?.sent) {
        prevRequest.sent = true;
        const newAccessToken = await refresh();
        prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axiosInstance(prevRequest);
      }
      return Promise.reject(error);
    }
  );
};

const getAxiosInstance = (api) => {
  switch (api) {
    case ApisEnum.Risk:
      return axiosPrivateRisk;
    case ApisEnum.Backoffice:
      return axiosPrivateBackoffice;
    default:
      return axiosPrivate;
  }
};
export default useAxiosPrivate;
