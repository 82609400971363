import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Avatar, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import PropTypes from "prop-types";

import cloud from "../../img/Cloud.svg";

import { getAuthTokenAzure } from "../utils/localStorage";

import FileErrorAlert from "../../components/BankPayments/FileErrorAlert";
import FileUploadAlert from "../../components/BankPayments/FileUploadAlert";
import { axiosClientBo } from "../commons/axiosClient";

function DragAndDrop({ type = ".csv" }) {
  const [t] = useTranslation("global");
  const [dragging, setDragging] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const fileInputRef = useRef(null);
  const overlayStyle = {
    display: showError ? "block" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#343C4699",
    zIndex: 999,
  };

  const handleDragEnterOrOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    try {
      if (!files || files.length === 0) {
        resetFileInput();
        return;
      }

      setIsLoad(true);
      const azureToken = getAuthTokenAzure();
      processFile(files[0], azureToken);
    } catch (error) {
      handleError(error);
    }
  };

  const processFile = async (file, azureToken) => {
    if (file.type === "text/csv") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const presignedURL = await getPresignedUrl(azureToken);
        if (!presignedURL) return;

        await uploadToS3(presignedURL, csv);
      };
      reader.readAsArrayBuffer(file);
    } else {
      handleError("El archivo debe ser de tipo CSV");
    }
  };

  const getPresignedUrl = async (azureToken) => {
    try {
      axiosClientBo.defaults.headers["Authorization"] = azureToken;
      const newNameCsv = generateNewCsvName();
      const options = {
        headers: {
          "x-api-key": process.env.REACT_APP_BACKOFFICE_APIKEY,
        },
      };
      const resurl = await axiosClientBo.get(`/presigned_urls/${newNameCsv}`, options);
      return resurl.data.pre_signed_url;
    } catch (error) {
      handleError(error);
      return null;
    }
  };

  const uploadToS3 = async (url, csvBlob) => {
    try {
      const options = { headers: { "Content-Type": "" } };
      await axios.put(url, csvBlob, options);
      finishUpload();
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    console.log("Error:", error);
    setIsLoad(false);
    setShowError(true);
    resetFileInput();
  };

  const finishUpload = () => {
    setIsLoad(false);
    resetFileInput();
    setFileUploaded(true);
    setTimeout(() => {
      handleDismissAlert();
    }, 5000);
  };

  const generateNewCsvName = () => {
    const date = new Date();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;
    return `${hour}${minute}${seconds}_pagos.csv`;
  };

  const resetFileInput = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleFileInput = (e) => {
    e.stopPropagation();
    const files = e.target.files;
    handleFiles(files);
  };
  const handleDismissAlert = () => {
    setFileUploaded(false);
  };
  const handleErrorClose = () => {
    setShowError(false);
  };
  let formatAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " + type;

  return (
    <div
      style={{
        border: dragging ? "2px dashed gray" : "2px dashed lightgray",
        height: "95%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: "16px",
        padding: "40px 16px 80px 16px",
        gap: "24px",
        margin: "auto",
        position: "relative",
        backgroundColor: dragging && !isLoad ? "#C2D1D975" : "#FFFFFF",
      }}
      onDragEnter={handleDragEnterOrOver}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragEnterOrOver}
      onDrop={handleDrop}
      onClick={() => fileInputRef.current.click()}
      data-testid="drag-area"
    >
      {showError && <div style={overlayStyle} onClick={(e) => e.stopPropagation()}></div>}
      {!isLoad && (
        <input
          ref={fileInputRef}
          type="file"
          accept={formatAccept}
          style={{ display: "none" }}
          onChange={handleFileInput}
        />
      )}
      {isLoad ? (
        <>
          <CircularProgress
            sx={{
              width: "70px",
              height: "70px",
            }}
            thickness={7}
            data-testid="progress-bar"
          />
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                color: "#343C46",
                fontSize: "24px",
                fontFamily: "Readex Pro",
                fontWeight: "600",
                lineHeight: "34px",
                letterSpacing: 0.25,
                wordWrap: "break-word",
              }}
            >
              Subiendo archivo
            </p>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              fontSize: "24px",
              color: "gray",
            }}
          >
            <Avatar
              alt="not batch"
              src={"../" + cloud}
              sx={{
                width: 70,
                height: 70,
                marginRight: "12px",
                margin: "10% auto",
              }}
            />
          </div>
          <Typography
            fontSize={"24px"}
            sx={{
              fontFamily: "Roboto",
              fontWeight: 600,
              lineHeight: "34px",
              letterSpacing: "0.247px",
              textAlign: "center",
              color: "#343C46",
            }}
          >
            {t("Risk.BankPayments.DragYourFilesHere")}
          </Typography>
          <Typography
            fontSize={"12px"}
            sx={{
              fontFamily: "Roboto",
              fontWeight: 400,
              lineHeight: "18px",
              letterSpacing: "0.25px",
              textAlign: "center",
              color: "#4C5866",
            }}
          >
            {t("Risk.BankPayments.FindComputerByClickingUploadFile")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<ArrowUpwardIcon />}
            sx={{
              borderRadius: "16px",
              textTransform: "none",
              borderColor: "white",
              fontWeight: "bold",
              height: "48px",
              width: "150px",
              fontSize: "12px",
              gap: "8px",
              backgroundColor: "#363853",
              "&:hover": {
                backgroundColor: "#363853",
              },
            }}
          >
            {t("Risk.BankPayments.UploadFile")}
          </Button>
          <FileUploadAlert fileUploaded={fileUploaded} onDismiss={handleDismissAlert} />
          <FileErrorAlert error={showError} onClose={handleErrorClose} />
        </>
      )}
    </div>
  );
}
DragAndDrop.propTypes = {
  type: PropTypes.string,
  fnReturn: PropTypes.func,
};
export default DragAndDrop;
